(function () {
  'use strict';

  angular
    .module('neo.home.facilities.newFacility')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('home.facilities.newFacility', {
        url: '/new-facility',
        templateUrl: 'home/facilities/new-facility/new-facility.tpl.html',
        controller: 'NewFacilityCtrl',
        controllerAs: 'vm',
        resolve :{
          acl: function ($q, AclService) {
            if (AclService.can('FACILITY', 'C')) {
              // Has proper permissions
              return true;
            } else {
              // Does not have permission
              return $q.reject('Unauthorized');
            }
          },
          towns: function (Poblacions) {
            return Poblacions.query().$promise;
          },
          townhalls : function(Ajuntaments){
            return Ajuntaments.query().$promise;
          }
        }
      });
  }
}());
